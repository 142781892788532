import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// AdminPanel
import Authentication from './AdminPanel/Components/Authentication/Authentication';
import ForgetPasswordForm from './AdminPanel/Components/ForgetPassword/ForgetPasswordForm';
import NewPasswordForm from './AdminPanel/Components/NewPasswordScreen/NewPasswordForm';
import Dashboard from './AdminPanel/Components/Dashboard/Dashboard';

//TeacherPanel
import AuthenticationTeacher from './TeacherPanel/Components/Authentication/Authentication';
import ForgetPasswordFormTeacher from './TeacherPanel/Components/ForgetPassword/ForgetPasswordForm';
import NewPasswordFormTeacher from './TeacherPanel/Components/NewPasswordScreen/NewPasswordForm';
import DashboardTeacher from './TeacherPanel/Components/Dashboard/Dashboard';

// HomePage
import HomePage from "./components/HomePage";
import CoursesPage from "./components/CoursePage/CoursesPage";
import InnerCoursePage from "./components/InnerCoursePage/InnerCoursePage";
import Contact from "./components/Contact/Contact";
import KTwenty from "./components/KTwenty/KTwenty";
import QandA  from "./components/QandA/QandA";
import ModalPage from "./components/Modal/ModalPage";
import Internship from "./components/Internship/Internship";
import Campus_Ambassador from "./components/Campus_Ambassador";
import Promotion_Executive from "./components/Promotion_Executive";
import Microsoft_post from './components/MicrosoftPost';
import Hr_post from './components/Hrblog';
import Google_post from './components/Googlepost';
import Web_dev_post from './components/WebDevelopmentPost';
import Digital_marketing_post from './components/DigitalMarketingPost';
import Software_quality_post from './components/SoftwareQuality';
import TrainingNeeds from "./components/TrainingNeeds/TrainingNeeds";
import Corporatetrainningbanner from "./components/Corporatetrainningbanner/banner"
import TrainingFeature from "./components/TrainingFeatures/TrainingFeature";
import GoogleReview from "./components/GoogleReview/Google_review";
// import WorkWithUs from "./components/WorkWithUs/WorkWithUs";
import CarrerPage from "./components/Carrer/CarrerPage";
import Affiliate from "./components/Affiliate/Affiliate";



import PartnerType from "./components/PartnerType/partnerType";
import BecomePartner from "./components/BecomePartner/becomePartner";
import PartneringStrategy from "./components/PartneringStrategy/partneringStrategy";


//Programming Inner Course Page
import Java_Inner_Course from './components/Programming/JavaInnerCourse';
import Java_Script_Inner_Course from './components/Programming/JavascriptInnerPage';
import Php_Inner_Course from './components/Programming/PhpInnerPage';
import Python_Inner_Course from './components/Programming/PythonInnerPage';
import Cplus_Inner_Course from './components/Programming/CplusInnerPage';
//Microsoft Inner Page 

import Microsoft_100 from "./components/MicrosoftInnerPage/Microsoft_100";
import Microsoft_101 from "./components/MicrosoftInnerPage/Microsoft_101";
import Microsoft_102 from "./components/MicrosoftInnerPage/Microsoft_102";
import Microsoft_103 from "./components/MicrosoftInnerPage/Microsoft_103";
import Microsoft_104 from "./components/MicrosoftInnerPage/Microsoft_104";
import Microsoft_105 from "./components/MicrosoftInnerPage/Microsoft_105";
import Microsoft_106 from "./components/MicrosoftInnerPage/Microsoft_106";
import Microsoft_107 from "./components/MicrosoftInnerPage/Microsoft_107";
import Microsoft_109 from "./components/MicrosoftInnerPage/Microsoft_109";
import Microsoft_110 from "./components/MicrosoftInnerPage/Microsoft_110";
import Microsoft_108 from "./components/MicrosoftInnerPage/Microsoft_108";
import Microsoft_117 from "./components/MicrosoftInnerPage/Microsoft_117";
import Microsoft_120 from "./components/MicrosoftInnerPage/Microsoft_120";
import Microsoft_118 from "./components/MicrosoftInnerPage/Microsoft_118";

//Hr Inner Page
import Hr_50 from "./components/HrInnerPage/Hr_50";
import Hr_51 from "./components/HrInnerPage/Hr_51";
import Hr_52 from "./components/HrInnerPage/Hr_52";
import Hr_53 from "./components/HrInnerPage/Hr_53";
import Hr_54 from "./components/HrInnerPage/Hr_54";
import Hr_55 from "./components/HrInnerPage/Hr_55";

//Web Development Inner Page

import Web_dev_20 from "./components/Web-dev-Inner-Page/Web_dev_20";
import Web_dev_21 from "./components/Web-dev-Inner-Page/Web_dev_21";
import Web_dev_22 from "./components/Web-dev-Inner-Page/Web_dev_22";
import Web_dev_23 from "./components/Web-dev-Inner-Page/Web_dev_23";
import Web_dev_24 from "./components/Web-dev-Inner-Page/Web_dev_24";
import Web_dev_25 from "./components/Web-dev-Inner-Page/Web_dev_25";


//AWS Inner Page

import Aws_150 from "./components/Aws Inner Page/Aws_150";
import Aws_151 from "./components/Aws Inner Page/Aws_151";
import Aws_152 from "./components/Aws Inner Page/Aws_152";
import Aws_153 from "./components/Aws Inner Page/Aws_153";
import Aws_154 from "./components/Aws Inner Page/Aws_154";
import Aws_155 from "./components/Aws Inner Page/Aws_155";
import Aws_156 from "./components/Aws Inner Page/Aws_156";
import Aws_157 from "./components/Aws Inner Page/Aws_157";
import Aws_158 from "./components/Aws Inner Page/Aws_158";
import Aws_159 from "./components/Aws Inner Page/Aws_159";
import Aws_160 from "./components/Aws Inner Page/Aws_160";
import Aws_161 from "./components/Aws Inner Page/Aws_161";
import Aws_162 from "./components/Aws Inner Page/Aws_162";
import Aws_163 from "./components/Aws Inner Page/Aws_163";
import Aws_164 from "./components/Aws Inner Page/Aws_164";
import Aws_165 from "./components/Aws Inner Page/Aws_165";
import Aws_166 from "./components/Aws Inner Page/Aws_166";
import Aws_167 from "./components/Aws Inner Page/Aws_167";
import Aws_168 from "./components/Aws Inner Page/Aws_168";
import Aws_169 from "./components/Aws Inner Page/Aws_169";
import Aws_170 from "./components/Aws Inner Page/Aws_170";
import Aws_171 from "./components/Aws Inner Page/Aws_171";
import Aws_172 from "./components/Aws Inner Page/Aws_172";
import Aws_173 from "./components/Aws Inner Page/Aws_173";
import Aws_174 from "./components/Aws Inner Page/Aws_174";
import Aws_175 from "./components/Aws Inner Page/Aws_175";




//Snowflake Inner Page
import Snowflake_200 from "./components/Snowflake Inner page/Snowflake_200";
import Snowflake_201 from "./components/Snowflake Inner page/Snowflake_201";
import Snowflake_202 from "./components/Snowflake Inner page/Snowflake_202";
import Snowflake_203 from "./components/Snowflake Inner page/Snowflake_203";
import Snowflake_204 from "./components/Snowflake Inner page/Snowflake_204";
import Snowflake_205 from "./components/Snowflake Inner page/Snowflake_205";
import Snowflake_206 from "./components/Snowflake Inner page/Snowflake_206";
import Snowflake_207 from "./components/Snowflake Inner page/Snowflake_207";
import Snowflake_208 from "./components/Snowflake Inner page/Snowflake_208";
import Snowflake_209 from "./components/Snowflake Inner page/Snowflake_209";
import Snowflake_210 from "./components/Snowflake Inner page/Snowflake_210";


//Devops Inner Page
import Devops_250 from "./components/Devops Inner Page/Devops_250";
import Devops_251 from "./components/Devops Inner Page/Devops_251";
import Devops_252 from "./components/Devops Inner Page/Devops_252";
import Devops_253 from "./components/Devops Inner Page/Devops_253";
import Devops_254 from "./components/Devops Inner Page/Devops_254";
import Devops_255 from "./components/Devops Inner Page/Devops_255";
import Devops_256 from "./components/Devops Inner Page/Devops_256";
import Devops_257 from "./components/Devops Inner Page/Devops_257";
import Devops_258 from "./components/Devops Inner Page/Devops_258";


//Marketing

import Email_marketing from "./components/Marketing Inner Page/Email_marketing";
import Digital_marketing from "./components/Marketing Inner Page/Digital_marketing";
import TrustedBy from "./components/TrustedBy/TrustedBy"

//design

// import Photoshop from "./components/Design Inner Page/Photoshop";
// import Canva from "./components/Design Inner Page/Canva";
import AdvisorForm from "./components/CorporateOurAdvisorForm/OurAdvisorForm";
import OurAdvisorForm from "./components/CorporateOurAdvisorForm/OurAdvisorForm";
import CorporateTrainingScreen from "./components/Screen/CorporateTrainingScreen";

//design

import Photoshop from "./components/Design Inner Page/Photoshop";
import Canva from "./components/Design Inner Page/Canva";
import WorkWithUs from "./components/WorkWithUs/WorkWithUs";
import Instructor from "./components/Instructor/Instructor";
import AboutUs from "./components/AboutUs/AboutUs";
import PartnerPage from "./components/Screen/PartnerPage";
import DashboardStudent from "./components/DashboardStudent/DashboardStudent";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/AdminPanel" element={<Authentication />} />
        <Route path="/forgotPassword" element={<ForgetPasswordForm />} />
        <Route path="/newPassword" element={<NewPasswordForm />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="trustedby" element={<TrustedBy />} />

        {/*Teacher Panel*/}
        <Route path="/TeacherPanel" element={<AuthenticationTeacher />} />
        <Route path="/forgotPassword" element={<ForgetPasswordFormTeacher />} />
        <Route path="/newPassword" element={<NewPasswordFormTeacher />} />
        <Route path="/Dashboard" element={<DashboardTeacher />} />

        {/* Add more routes here */}
        
        <Route path="/courses-link" element={<CoursesPage />} />
        <Route path="/inner-course-link" element={<InnerCoursePage />} />
        <Route path="/contact-us" element={<Contact />}/>
        <Route path="/K-20" element={<KTwenty />} />
        <Route path="/QandA" element={<QandA />} />
        <Route path="/microsoft-post" element={<Microsoft_post />} />
        <Route path="/hr-post" element={<Hr_post />} />
        <Route path="/google-post" element={<Google_post />} />
        <Route path="/web-dev-post" element={<Web_dev_post />} />
        <Route path="/digital-marketing-post" element={<Digital_marketing_post />} />
        <Route path="/software-quality-post" element={<Software_quality_post />} />
        <Route path="/internship" element={<Internship />} />
        <Route path="/campus-ambassador" element={<Campus_Ambassador />} />
        <Route path="/promotion-executive" element={<Promotion_Executive />} />
        <Route path="trainingneeds" element={<TrainingNeeds />} />
        <Route path="trainingbanner" element={<Corporatetrainningbanner />} />
        <Route path="features" element={<TrainingFeature />} />
        <Route path="ouradvisor" element={<OurAdvisorForm />} />
        <Route path="corporatetraining" element={<CorporateTrainingScreen/>} />
        <Route path="/reviews" element={<GoogleReview/>} />
        <Route path="/work" element={<WorkWithUs />} />
        <Route path="/carrer" element={<CarrerPage />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/dashboardstudent" element={<DashboardStudent />} />

        <Route path="/partner1" element={<PartnerType/>}  />
        <Route path="/partner2" element={<BecomePartner/>}  />
        <Route path="/partner3" element={<PartneringStrategy/>}  />
        <Route path="/partner" element={<PartnerPage />} />



        {/* Programming Inner Routes */}
        <Route path='/java-detail' element={<Java_Inner_Course />} />
        <Route path='/java-script-detail' element={<Java_Script_Inner_Course />} />
        <Route path='/php-detail' element={<Php_Inner_Course />} />
        <Route path='/python-detail' element={<Python_Inner_Course />} />
        <Route path='/cplush-detail' element={<Cplus_Inner_Course />} />

        {/* Microsoft Inner Page Routes */}

        <Route path='/microsoft-100' element={<Microsoft_100 />} />
        <Route path='/microsoft-101' element={<Microsoft_101 />} />
        <Route path='/microsoft-102' element={<Microsoft_102 />} />
        <Route path='/microsoft-103' element={<Microsoft_103 />} />
        <Route path='/microsoft-104' element={<Microsoft_104 />} />
        <Route path='/microsoft-105' element={<Microsoft_105 />} />
        <Route path='/microsoft-106' element={<Microsoft_106 />} />
        <Route path='/microsoft-107' element={<Microsoft_107 />} />
        <Route path='/microsoft-109' element={<Microsoft_109 />} />
        <Route path='/microsoft-110' element={<Microsoft_110 />} />
        <Route path='/microsoft-108' element={<Microsoft_108 />} />
        <Route path='/microsoft-117' element={<Microsoft_117 />} />
        <Route path='/microsoft-120' element={<Microsoft_120 />} />
        <Route path="/microsoft-118" element={<Microsoft_118 />} />

        {/* Hr Inner Page */}
        <Route path='/hr-50' element={<Hr_50 />} />
        <Route path='/hr-51' element={<Hr_51 />} />
        <Route path='/hr-52' element={<Hr_52 />} />
        <Route path='/hr-53' element={<Hr_53 />} />
        <Route path='/hr-54' element={<Hr_54 />} />
        <Route path='/hr-55' element={<Hr_55 />} />
        
        {/* Web Development Inner Page */}
        <Route path='/web-dev-20' element={<Web_dev_20 />} />
        <Route path='/web-dev-21' element={<Web_dev_21 />} />
        <Route path='/web-dev-22' element={<Web_dev_22 />} />
        <Route path='/web-dev-23' element={<Web_dev_23 />} />
        <Route path='/web-dev-24' element={<Web_dev_24 />} />
        <Route path='/web-dev-25' element={<Web_dev_25 />} />
        

        {/* AWS Inner Page */}

        <Route path='/aws-150' element={<Aws_150 />} />
        <Route path='/aws-151' element={<Aws_151 />} />
        <Route path='/aws-152' element={<Aws_152 />} />
        <Route path='/aws-153' element={<Aws_153 />} />
        <Route path='/aws-154' element={<Aws_154 />} />
        <Route path='/aws-155' element={<Aws_155 />} />
        <Route path='/aws-156' element={<Aws_156 />} />
        <Route path='/aws-157' element={<Aws_157 />} />
        <Route path='/aws-158' element={<Aws_158 />} />
        <Route path='/aws-159' element={<Aws_159 />} />
        <Route path='/aws-160' element={<Aws_160 />} />
        <Route path='/aws-161' element={<Aws_161 />} />
        <Route path='/aws-162' element={<Aws_162 />} />
        <Route path='/aws-163' element={<Aws_163 />} />
        <Route path='/aws-164' element={<Aws_164 />} />
        <Route path='/aws-165' element={<Aws_165 />} />
        <Route path='/aws-166' element={<Aws_166 />} />
        <Route path='/aws-167' element={<Aws_167 />} />
        <Route path='/aws-168' element={<Aws_168 />} />
        <Route path='/aws-169' element={<Aws_169 />} />
        <Route path='/aws-170' element={<Aws_170 />} />
        <Route path='/aws-171' element={<Aws_171 />} />
        <Route path='/aws-172' element={<Aws_172 />} />
        <Route path='/aws-173' element={<Aws_173 />} />
        <Route path='/aws-174' element={<Aws_174 />} />
        <Route path='/aws-175' element={<Aws_175 />} />


        {/* Snowflake Inner Page */}

        <Route path='/snowflake-200' element={<Snowflake_200 />} />
        <Route path='/snowflake-201' element={<Snowflake_201 />} />
        <Route path='/snowflake-202' element={<Snowflake_202 />} />
        <Route path='/snowflake-203' element={<Snowflake_203 />} />
        <Route path='/snowflake-204' element={<Snowflake_204 />} />
        <Route path='/snowflake-205' element={<Snowflake_205 />} />
        <Route path='/snowflake-206' element={<Snowflake_206 />} />
        <Route path='/snowflake-207' element={<Snowflake_207 />} />
        <Route path='/snowflake-208' element={<Snowflake_208 />} />
        <Route path='/snowflake-209' element={<Snowflake_209 />} />
        <Route path='/snowflake-210' element={<Snowflake_210 />} />


        {/* Devops Inner Page */}

        <Route path='/devops-250' element={<Devops_250 />} />
        <Route path='/devops-251' element={<Devops_251 />} />
        <Route path='/devops-252' element={<Devops_252 />} />
        <Route path='/devops-253' element={<Devops_253 />} />
        <Route path='/devops-254' element={<Devops_254 />} />
        <Route path='/devops-255' element={<Devops_255 />} />
        <Route path='/devops-256' element={<Devops_256 />} />
        <Route path='/devops-257' element={<Devops_257 />} />
        <Route path='/devops-258' element={<Devops_258 />} />

        {/* Marketing Inner Page */}
        <Route path='/email-marketing' element={<Email_marketing />} />
        <Route path='/digital-marketing' element={<Digital_marketing />} />

        {/* Design Inner Page */}

       <Route path='/photoshop' element={<Photoshop />} />
        <Route path='/canva' element={<Canva />} /> 

        <Route path="/modal-link" element={<ModalPage />} />

      </Routes>
    </Router>
  );
}

export default App;
