import React from "react";
import "./Affiliate.css";
import work from "../../assets/images/workwith1.png";
import NavBar from "../Navbar/Navbar";
import CareerRelatedPrograms from "../CareerRelatedProgram/CareerRelatedPrograms";
import GoogleReview from "../GoogleReview/Google_review";
import Footer from "../Footer/Footer";

const Affiliate = () => {
  return (
    <>
      <NavBar />

      <section className="affiliate-container">
        <div className="content-container">
          <div className="row">
            <div className="col-4 ">
              <b>
                <h1
                  style={{
                    fontSize: "40px",
                    marginTop: "50px",
                    color: "#f04c24",
                    marginLeft: "50px",
                  }}
                >
                  Become An Affiliate
                </h1>
              </b>
              <p className="affiliateContent">
                Explore remote-friendly, flexible opportunities and join our
                mission to make work life simpler, more pleasant, and more
                productive.
              </p>
              <a href="/courses-link" className="affiliatecareers-button">
                View Careers
              </a>
            </div>

            <div className="affiliateimg col-8">
              <img className="affiliate" src={work} alt="" srcset="" />
            </div>
          </div>
        </div>
      </section>
      <CareerRelatedPrograms />
      <GoogleReview />
      <Footer />
    </>
  );
};

export default Affiliate;
