
import React from "react";
import "./DashboardStudent.css";

const DashboardStudent = () => {
  return (
    <div className="student">
      <div className="dashboard-container">
        <div className="row">
       
          <nav className="col-md-2 sidebar bg-light">
            <div className="sidebar-content">
              <h2 className="logo">DigiPaath</h2>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <a className="nav-link " href="#">
                    <i className="fas fa-home"></i> Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <i className="fas fa-book"></i> Courses
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <i className="fas fa-chalkboard-teacher"></i> Lessons
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <i className="fas fa-tasks"></i> Assessments
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <i className="fas fa-certificate"></i> Certification
                  </a>
                </li>
              </ul>
              <div className="sidebar-upgrade">
                <button className="btn btn-primary w-100">
                  <i className="fas fa-lock"></i> Unlock Premium
                </button>
              </div>
            </div>
          </nav>

       
          <div className="col-md-10 main-content">
        
            <div className="d-flex justify-content-between align-items-center mt-3 header">
              <div>
                <h2>Welcome Back Jack</h2>
                <p>Here overview of your course</p>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search.."
                />
                <button className="btn btn-dark ms-3">Sign In</button>
              </div>
            </div>

          
            <div className="row mt-4 stats-section">
              <div className="col-md-4">
                <div className="card stat-card position-relative">
                  <div className="card-body">
                    <div>
                      <h5>Total Enrolled</h5>
                      <p>5000</p>
                    </div>
                    <div className="icon-container position-absolute top-0 end-0 m-2">
                      <i className="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card stat-card position-relative">
                  <div className="card-body">
                    <h5>Completed</h5>
                    <p>50</p>
                    <div className="icon-container position-absolute top-0 end-0 m-2">
                    <i
                      className="fas fa-check-circle"
                     
                    ></i>
                     </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card stat-card position-relative">
                  <div className="card-body">
                    <h5>Quiz Score</h5>
                    <p>50</p>
                    <div className="icon-container position-absolute top-0 end-0 m-2">
                    <i
                      className="fas fa-clipboard-check "
                   
                    ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         
            <div className="row mt-4 recent-courses">
              <h4>Recent Enrolled Courses</h4>
              <div className="col-md-4">
                <div className="card course-card">
                  <div className="card-body">
                    <h5>Mobile App Development</h5>
                    <p>4/10 Lessons</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "40%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card course-card">
                  <div className="card-body">
                    <h5>Game Development</h5>
                    <p>4/20 Lessons</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
              <div className="card course-card">
              <div className="card-body">
              <h4>Daily Progress</h4>
              <div >
                <button className="btn btn-outline-dark my-2 ">
                  Game Development
                </button>
              </div>
              <div >
                <button className="btn btn-outline-dark my-2">UI/UX</button>
              </div>
              <div >
                <button className="btn btn-outline-dark my-2">
                  Web Development
                </button>
              </div>
            </div>
            </div>
            </div>
            </div>

{/*           
            <div className="row mt-4 daily-progress">
             
            </div> */}

        
            <div className="row mt-4 upcoming-classes">
              <h4>Upcoming Classes</h4>
              <div className="col-md-6">
                <div className="card class-card">
                  <div className="card-body">
                    <h5>Cloud Computing Essentials</h5>
                    <p>5:30 PM</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card class-card">
                  <div className="card-body">
                    <h5>Mobile App Development Trends</h5>
                    <p>2:30 PM</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardStudent;
