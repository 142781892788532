
import React from "react";
import ImageLogo from "../../assets/images/logo1.png";
import { FaCrown, FaAward, FaThumbsUp, FaHandshake, FaTools, FaPuzzlePiece, FaLightbulb, FaMobileAlt } from "react-icons/fa"; 
import NavBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const AboutUs = () => {
  return (
    <>
    <NavBar />
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12 text-center text-lg-left mt-lg-5">
            <b><h1 style={{color:"#ff5722"}}>About DigiPaath</h1></b>
            {/* <img
              src={ImageLogo}
              alt="Logo"
              className="logo"
              style={{ width: "100px", marginTop: "10px" }}
            /> */}
          </div>
          <div className="col-lg-6 col-sm-12">
            <p>
              DigiPaath is an Ed-Tech Startup & Subsidiary of DigiiDunia. We are
              passionate about transforming the way students learn and grow,
              bringing innovation and technology to the forefront of education.
              Our platform is designed to empower learners and educators alike,
              providing interactive and personalized experiences that bridge the
              gap between traditional and digital learning.
            </p>
            <p>
              With a focus on accessibility and inclusivity, we are committed to
              equipping learners of all ages with the skills they need to thrive
              in the modern world.
            </p>
          </div>
        </div>

        <div className=" mb-5">
         <b><h2 style={{color:"#ff5722"}}>Achievements</h2></b> 
          <p>
            Our commitment to excellence has led us to achieve significant
            milestones along our journey. Here are some of our notable
            achievements.
          </p>
        </div>
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4 ">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
                <FaCrown
                  size={30}
                  className="mb-3"
                  style={{ color: "#ff5722" }}
                />
                <h5 className="card-title">Trusted by Thousands</h5>
                <p className="card-text">
                  We have successfully served thousands of students, helping
                  them unlock their potential and achieve their career goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
                <FaAward
                  size={30}
                  className="mb-3"
                  style={{ color: "#ff5722" }}
                />
                <h5 className="card-title">Award-Winning Courses</h5>
                <p className="card-text">
                  Our courses have received recognition and accolades in the
                  industry for their quality, depth of content, and effective
                  teaching methodologies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
                <FaThumbsUp
                  size={30}
                  className="mb-3"
                  style={{ color: "#ff5722" }}
                />
                <h5 className="card-title">Positive Student Feedback</h5>
                <p className="card-text">
                  We take pride in the positive feedback we receive from our
                  students, who appreciate the practicality and relevance of our
                  course materials.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
                <FaHandshake
                  size={30}
                  className="mb-3"
                  style={{ color: "#ff5722" }}
                />
                <h5 className="card-title">Industry Partnerships</h5>
                <p className="card-text">
                  We have established strong partnerships with industry leaders,
                  enabling us to provide our students with access to the latest
                  tools and technologies.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
        <b> <h3 style={{color:"#ff5722"}}>Our Goals</h3></b> 
          <p>
            At DigiPaath, our goal is to empower individuals from all
            backgrounds to thrive in the world of design and development. We
            believe that education should be accessible and transformative,
            enabling learners to pursue their passions and make a meaningful
            impact.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
              <FaPuzzlePiece size={30} className="mb-3" style={{ color: "#ff5722" }} />
                <h5 className="card-title">Promote Collaboration and Community</h5>
                <p className="card-text">
                We believe in the power of collaboration and peer learning.
                  Our platform fosters a supportive and inclusive community
                  where learners can connect, share insights, and grow together.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
              <FaMobileAlt size={30} className="mb-3" style={{ color: "#ff5722" }} />
                <h5 className="card-title">Stay Ahead of the Curve</h5>
                <p className="card-text">
                The digital landscape is constantly evolving, and we strive to
                   stay at the forefront of industry trends. We regularly update
                   our course content to ensure our students receive the latest
                   knowledge and skills.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
              <FaTools size={30} className="mb-3 " style={{ color: "#ff5722" }} />
                <h5 className="card-title">Provide Practical Skills</h5>
                <p className="card-text">
                We focus on delivering practical skills that are relevant to
                 the current industry demands. Our courses are designed to
                 equip learners with the knowledge and tools needed to excel in
                 their chosen field.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 bg-light border-0">
              <div className="card-body">
              <FaLightbulb size={30} className="mb-3" style={{ color: "#ff5722" }} />
                <h5 className="card-title">Foster Creative Problem-Solving</h5>
                <p className="card-text">
                We encourage creative thinking and problem-solving abilities,
                   allowing our students to tackle real-world challenges with
                  confidence and innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default AboutUs;
